<template>
  <div class="container-fluid events-banner" v-if="eventos.length">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Eventos em destaque:</h1>
        </div>
      </div>
      <div class="row">

        <div class="col-md-4 col-xs-12" v-for="event in eventos" :key="event.id">
          <v-link :title="event.name" :href="'/evento/' + event.id + '/' + event.slug + '/'">
            <div class="card">
              <div class="card-header" v-html="event.start_at.concat(status(event))"></div>
              <img class="card-img-top" :src="event.image" alt="Card image">
              <div class="card-body">
                <h4 class="card-title">{{ event.name }}</h4>
                <p class="card-text hidden-xs">
                  Inscrições de  {{ (new Date(event.subscription_start)).toLocaleString("pt-BR", {timeZone: "America/Sao_paulo",year: 'numeric', month: 'numeric', day: 'numeric'}) }} até {{ (new Date(event.subscription_end)).toLocaleString("pt-BR", {timeZone: "America/Sao_paulo",year: 'numeric', month: 'numeric', day: 'numeric'}) }}
                </p>
              </div>
            </div>
          </v-link>
        </div>

      </div>
    </div>
  </div>
  <BannerHome v-else></BannerHome>
</template>

<script>
import Events from "@/api/Events";
import BannerHome from "./BannersHome";
import VLink from "./VLink";

export default {
  name: "BannerEventos",
  components: {BannerHome,VLink},
  data() {
    return {
      eventos: [],
    };
  },
  methods: {
    status(event){
        return Events.get_status(event);
    },
    order_by_date(array){
      return array.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.start_event_at) - new Date(b.start_event_at);
      });
    }
  },
  mounted() {
    let Eventos = Events.get_destaques();
    Eventos.then((request) => {
      if (request.status == 200) {
        if(request.data.length > 3){
          request.data = this.order_by_date(request.data);
          request.data.splice(3,request.data.length - 3)

          this.eventos = request.data;
        }else{
          this.eventos = this.order_by_date(request.data);
        }
      }
    });
  }
}
</script>
<style>


</style>